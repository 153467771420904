
import page from '@/mixins/page';
import _ from 'lodash';

export default {
  mixins: [page],
  head() {
    let jsonLdScripts = [];

    jsonLdScripts = [...this.$helpers.maisonsToJsonLd(this.maisons)];

    const localBusinessJsonLd = this.$helpers.localBusinessJsonLd(
      this.component.title,
      this.component.description,
      `${process.env.APP_URL}/${this.$route.path}`,
      '',
      this.maisons,
    );

    if (localBusinessJsonLd) {
      jsonLdScripts.push({
        type: 'application/ld+json',
        json: localBusinessJsonLd,
      });
    }

    return {
      script: jsonLdScripts,
    };
  },
  data() {
    return {
      maisons: null,
    };
  },
  async mounted() {
    if (!this.component.maisons) {
      return;
    }

    let params = new URLSearchParams();
    params.append('id', `in:${this.component.maisons}`);

    const { data: maisons } = await this.$api.$get(`/api/v1/maisons?${params}`);

    this.maisons = maisons;
  },
  computed: {
    withGallery() {
      return this.component.hasOwnProperty('use_gallery');
    },
  },
};
