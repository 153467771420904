
export default {
  name: 'AppViewMoreButton',
  props: {
    to: {
      type: Object | String,
      default: '#',
    },
  },
  computed: {
    link() {
      return this.to ?? '#';
    },
  },
};
